.header-wrapping {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    box-shadow:
        0 2px 3px hsla(0, 0%, 4%, 0.1),
        0 0 0 1px hsla(0, 0%, 4%, 0.1);
}
.search-header {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    align-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "heading"
        "search-form";
}
.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-area: heading;
}
.heading img {
    display: inline-block;
}
.heading h1 {
    display: inline-block;
    margin-left: 9px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.pagetack-search-form {
    border: 2px solid #22343e;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px;
    margin: 0 34px 26px 34px;
    grid-area: search-form;
}
.pagetack-search-form input {
    border: none;
    width: 100%;
    font-size: 1em;
    padding-left: 0;
    padding-right: 0;
}
.pagetack-search-form input:focus {
    outline: none;
}
.pagetack-search-form button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}
img.search-icon {
    height: 18px;
}
img.clear-icon {
    height: 12px;
}

.track-container {
    padding-top: 132.5px;
    padding-left: 18px;
    padding-right: 18px;
}
.j-and-t-button,
.flash-button,
.kerry-button {
    display: inline-block;
    text-decoration: none;
    font-family: "sukhumvit", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100px;
    border-radius: 20px;
    text-align: center;
    margin: 14px 0;
}
.flash-button {
    color: black;
    background-color: #f8ee1d;
}
.flash-button:hover {
    background-color: #e6dc1c;
    transition: 0.2s;
    transition-delay: 0.05s;
}
.kerry-button {
    color: white;
    background-color: #ed6d00;
}
.kerry-button:hover {
    background-color: #d46202;
    transition: 0.2s;
    transition-delay: 0.05s;
}
.j-and-t-button {
    color: white;
    background-color: #fd0e15;
}
.j-and-t-button:hover {
    background-color: #e00d14;
    transition: 0.2s;
    transition-delay: 0.05s;
}

.profile-item {
    background-color: white;
    border-radius: 14px;
    box-shadow:
        0 2px 3px hsla(0, 0%, 4%, 0.1),
        0 0 0 1px hsla(0, 0%, 4%, 0.1);
    margin-top: 15px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.profile-item h2 {
    font-size: 25px;
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
}
.gray-background {
    background-color: #f6f6f6;
    width: 100%;
}
.profile-subjects {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 0;
}
.profile-subjects p {
    margin: 0;
}
.profile-tracking {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-tracking p {
    display: block;
    margin: 4px 0;
}
.profile-tracking h3 {
    display: block;
    margin: 0;
    font-size: 25px;
    font-weight: normal;
}
.track-search-error {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    font-size: 18px;
    font-family: sans-serif;
    color: #a9a9a9;
}
.track-search-error h2 {
    color: #a9a9a9;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
}
.track-search-error img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.track-search-error p {
    text-align: center;
    margin-top: 0;
}
@media screen and (min-width: 830px) {
    .search-header {
        grid-template-columns: 320px auto;
        grid-template-rows: 1fr;
        grid-template-areas: "heading search-form";
    }
    .pagetack-search-form {
        margin: auto 10px;
    }
    .track-container {
        padding-top: 65.5px;
    }
}

html {
  background-color: #F6F6F6;;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'sukhumvit';
  src: url('./fonts/sukhumvit/sukhumvitbold-webfont.eot');
  src: url('./fonts/sukhumvit/sukhumvitbold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/sukhumvit/sukhumvitbold-webfont.woff2') format('woff2'),
       url('./fonts/sukhumvit/sukhumvitbold-webfont.woff') format('woff'),
       url('./fonts/sukhumvit/sukhumvitbold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'sukhumvit';
  src: url('./fonts/sukhumvit/sukhumvitlight-webfont.eot');
  src: url('./fonts/sukhumvit/sukhumvitlight-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/sukhumvit/sukhumvitlight-webfont.woff2') format('woff2'),
       url('./fonts/sukhumvit/sukhumvitlight-webfont.woff') format('woff'),
       url('./fonts/sukhumvit/sukhumvitlight-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'sukhumvit';
  src: url('./fonts/sukhumvit/sukhumvitreg-webfont.eot');
  src: url('./fonts/sukhumvit/sukhumvitreg-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.woff2') format('woff2'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.woff') format('woff'),
       url('./fonts/sukhumvit/sukhumvitreg-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
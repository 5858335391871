.app-section {
    margin: 0;
    padding: 0 34px;
}
.app-container {
    max-width: 505px;
    margin-left: auto;
    margin-right: auto;
}
.search-container {
    position: relative;
}
.search-form {
    margin-left: auto;
    margin-right: auto;
    height: 50vh;
}
.search-form input {
    position: absolute;
    font-size: 1em;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    border: 2px solid #22343E;
    border-radius: 30px;
}
.search-form input:focus {
    outline: none;
}
.search-form button {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(6px, -2px);
    border-radius: 0 20px 20px 0;
    border: none;
    padding: 9px 12px;
    background-color: white;
}
.search-icon {
    height: 22px;
}

.hero {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    min-width: fit-content;
}
.hero img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.hero h1 {
    text-align: center;
    margin-top: 0px;
}
.search-form h3 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 60px);
    color: #a9a9a9;
}